import image1 from '../assets/image1.webp';
import image2 from '../assets/image2.webp';
import image3 from '../assets/image3.webp';
import image4 from '../assets/image4.webp';
import image5 from '../assets/image5.webp';
import image6 from '../assets/image6.webp';
import image7 from '../assets/image7.webp';
import image8 from '../assets/image8.webp';
import image9 from '../assets/image9.webp';

const products = [
  {
    id: 1,
    name: '3D Printed Phone Holder',
    price: '11.20',
    image: image1,
    badge: '',
    description: 'This 3D printed phone holder combines sleek modern design with practical functionality. It securely holds your phone in place whether you’re at home, work, or on the go. Lightweight and durable, it’s an essential accessory for tech enthusiasts.',
  },
  {
    id: 2,
    name: 'Wooden Pencil Holder',
    price: '13.22',
    image: image2,
    badge: '',
    description: 'Crafted from high-quality wood, this pencil holder adds a touch of elegance to any desk or workspace. With ample space for pens, pencils, and other stationery, it keeps your essentials organized and within easy reach. Ideal for home offices and creative professionals.',
  },
  {
    id: 3,
    name: 'Slider Box',
    price: '11.33',
    image: image3,
    badge: '',
    description: 'The slider box is designed for convenience and versatility. Perfect for storing small items like jewelry, coins, or office supplies, its compact size fits neatly on any shelf or countertop. Slide open to reveal its contents with ease, making it a practical storage solution for everyday use.',
  },
  {
    id: 4,
    name: 'Custom Ring Plate',
    price: '13.84',
    image: image7,
    badge: 'Customizable',
    description: 'Create your own style statement with this customizable ring plate. Personalize it with initials, names, or meaningful dates to make it uniquely yours. Crafted from durable materials, it’s perfect for showcasing your favorite rings or gifting to someone special.',
  },
  {
    id: 5,
    name: 'Personalized Ring Holder',
    price: '18.23',
    image: image5,
    badge: 'Customizable',
    description: 'Elegantly display and organize your rings with this personalized ring holder. Add a custom touch by engraving your name or a special message. Its sleek design and premium materials make it a standout piece on any vanity or dresser.',
  },
  {
    id: 6,
    name: 'Leather Ring Holder',
    price: '15.00',
    image: image6,
    badge: 'Customizable',
    description: 'Handcrafted from genuine leather, this ring holder combines luxury with functionality. Its soft interior protects your rings from scratches, while the stylish exterior adds a touch of sophistication to your dressing table. Ideal for storing and showcasing your favorite rings.',
  },
  {
    id: 7,
    name: 'Custom Accessory Holder',
    price: '15.73',
    image: image4,
    badge: 'Customizable',
    description: 'Organize your accessories in style with this customizable holder. Personalize it with your initials or a favorite quote to create a unique piece that reflects your personality. With compartments for watches, bracelets, and more, it’s perfect for both everyday use and special occasions.',
  },
  {
    id: 8,
    name: 'Leather Accessory Holder',
    price: '16.87',
    image: image8,
    badge: 'Customizable',
    description: 'Keep your accessories neatly organized with this sleek leather holder. Crafted from premium leather, it offers durability and timeless elegance. With multiple compartments and a compact design, it’s ideal for storing watches, cufflinks, and other essentials.',
  },
  {
    id: 9,
    name: 'Floral Accessory Holder',
    price: '16.25',
    image: image9,
    badge: 'Customizable',
    description: 'Add a touch of luxury to your accessories collection with this floral accessory holder. Featuring a beautiful floral pattern and elegant design, it’s both functional and decorative. Perfect for storing jewelry, hair accessories, or small keepsakes.',
  },
];

export default products;
