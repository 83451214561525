import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Return.module.css'; // Assuming you have a CSS module for styling

const Return = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.returnInfo}>
      <h1>RETURN POLICY</h1>
      <p>At {siteName}, we prioritize your satisfaction and aim to ensure a seamless shopping experience. Please review our guidelines below for initiating a return:</p>
      
      <h2>1. RETURN CONDITIONS</h2>
      <p>Items must be returned in their original, unused condition with all tags and packaging intact. Customized products can only be returned if they arrive defective or damaged.</p>
      
      <h2>2. RETURN PERIOD</h2>
      <p>You have 30 days from the delivery date to initiate a return. Contact our customer support team promptly to start the process.</p>
      
      <h2>3. STEPS TO RETURN</h2>
      <p><strong>Request Authorization:</strong> Email our customer support to request a return authorization code.</p>
      <p><strong>Prepare Your Package:</strong> Securely pack the item in its original box, including all accessories and documentation. Affix the provided authorization code.</p>
      <p><strong>Ship the Item:</strong> Use a traceable shipping service for return shipping. Customers are responsible for return shipping costs, except in cases of our error.</p>
      
      <h2>4. REFUNDS AND EXCHANGES</h2>
      <p>Upon receiving and verifying the condition of your returned item, we will process your refund or exchange. Refunds will be issued to the original payment method and typically reflect within 5-7 business days. Exchanges are subject to product availability. If a replacement isn't available, we may offer a refund. Original shipping fees are non-refundable unless the return results from our mistake.</p>
      
      <h2>5. HANDLING DAMAGED OR INCORRECT ITEMS</h2>
      <p>If you receive a damaged or incorrect item, notify our customer service team immediately with photos and details. We'll cover return shipping costs for such items and provide a replacement or refund.</p>
      
      <h2>6. NON-RETURNABLE ITEMS</h2>
      <p>Some items, such as hygiene-sensitive products, can't be returned due to health reasons. Check specific product pages for details or contact our customer support for clarification.</p>
      
      <h2>7. RETURN POLICY EXCEPTIONS</h2>
      <p>Items purchased from third-party sellers or external platforms aren't eligible for returns through {siteName}. Review the seller's return policy before making a purchase.</p>
      
      <p>For any further questions or assistance with your return, reach out to our support team at <a href={`mailto:support@${siteName.toLowerCase()}`}>support@{siteName}</a>. At {siteName}, we're committed to delivering exceptional service and ensuring your satisfaction with every purchase.</p>
    </div>
  );
};

export default Return;
