// SiteContext.js
import React, { createContext, useContext } from 'react';

const SiteContext = createContext();

export const SiteProvider = ({ children }) => {
  const siteInfo = {
    siteName: "BookOfStarburst.Com",
    slogan: "Discover the beauty of our products",
    info: "Explore a wide range of unique items.",
    thankYouProduct: "Thank you for your interest in our product.",
    thankYouSubscription: "Thank you for subscribing!",
    thankYouOrder: "Thank you for your order."
  };

  return (
    <SiteContext.Provider value={siteInfo}>
      {children}
    </SiteContext.Provider>
  );
};

export const useSite = () => {
  return useContext(SiteContext);
};
