import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Privacy.module.css'; // Assuming you have a CSS module for styling

const Privacy = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.privacyInfo}>
      <h1>PRIVACY COMMITMENT</h1>
      <p>At {siteName}, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website and use our services.</p>

      <h2>1. INFORMATION COLLECTION</h2>
      <p><strong>Personal Data:</strong> We collect personal details such as your name, email address, postal address, and payment information when you make a purchase on BookOfStarburst.Com.</p>
      <p><strong>Interaction Data:</strong> We use cookies and similar technologies to gather information about your browsing behavior, IP address, and device type to enhance your user experience and improve our services.</p>

      <h2>2. INFORMATION USAGE</h2>
      <p><strong>Transaction Processing:</strong> Your personal data is used to process and fulfill orders, communicate order statuses, and provide customer support.</p>
      <p><strong>Personalization:</strong> With your consent, we personalize your shopping experience based on your preferences and past interactions with BookOfStarburst.Com.</p>
      <p><strong>Service Improvement:</strong> We analyze collected data to enhance our website functionality, product offerings, and overall customer experience.</p>

      <h2>3. INFORMATION DISCLOSURE</h2>
      <p><strong>Third-Party Partners:</strong> We may share your information with trusted third-party service providers who assist us in delivering our products and services, such as shipping companies and payment processors. These partners are bound by strict confidentiality agreements.</p>
      <p><strong>Legal Requirements:</strong> We may disclose your information if required by law or in good faith belief that such action is necessary to comply with legal obligations, protect our rights, or safeguard the safety of others.</p>

      <h2>4. DATA SECURITY</h2>
      <p>We employ industry-standard security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet or electronic storage is entirely secure.</p>

      <h2>5. YOUR PRIVACY CHOICES</h2>
      <p><strong>Communication Preferences:</strong> You can manage your communication preferences, including opting out of marketing communications, by contacting us directly or using the unsubscribe link provided in our emails.</p>
      <p><strong>Cookies:</strong> You can control cookies through your browser settings. Please note that disabling cookies may limit certain functionalities of BookOfStarburst.Com.</p>

      <h2>6. CHILDREN'S PRIVACY</h2>
      <p>We do not knowingly collect personal information from individuals under the age of 16. If we discover that we have inadvertently gathered personal data from a child, we promptly delete it from our systems.</p>

      <h2>7. POLICY UPDATES</h2>
      <p>We may update our Privacy Policy periodically to reflect changes in our practices and legal requirements. We encourage you to review this policy regularly to stay informed about how we protect your privacy.</p>

      <p>If you have any questions or concerns about our privacy practices at {siteName}, please contact us at <a href={`mailto:info@${siteName.toLowerCase()}`}>info@{siteName}</a>. Your privacy matters to us, and we are committed to addressing your inquiries promptly.</p>
    </div>
  );
};

export default Privacy;
