import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useSite } from '../context/SiteContext';
import styles from './Hero.module.css';
import backgroundImage from '../assets/hero-background.webp'; // Ensure you have a background image
import highQualityIcon from '../assets/high-quality-icon.png'; // Replace with your icon image paths
import fastDeliveryIcon from '../assets/fast-delivery-icon.png'; // Replace with your icon image paths
import supportIcon from '../assets/support-icon.png'; // Replace with your icon image paths

const Hero = () => {
  const { slogan, info } = useSite();

  // Example advantages of the company with corresponding icons
  const advantages = [
    { text: "High-quality products", icon: highQualityIcon },
    { text: "Fast delivery", icon: fastDeliveryIcon },
    { text: "Excellent customer support", icon: supportIcon }
  ];

  return (
    <section className={styles.hero} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={styles.overlay}></div>
      <div className={styles.content}>
        <h1 className={styles.slogan}>{slogan}</h1>
        <p className={styles.description}>{info}</p>
        <ScrollLink
          to="products-section"
          smooth={true}
          duration={500}
          offset={-70}  // Adjust the offset as needed
          className={styles.orderButton}
        >
          Order Now
        </ScrollLink>
        {/* Advantages section */}
        <div className={styles.advantages}>
          <h2 className={styles.advantagesTitle}>Our Advantages</h2>
          <div className={styles.advantagesList}>
            {advantages.map((advantage, index) => (
              <div key={index} className={styles.advantageItem}>
                <img src={advantage.icon} alt={advantage.text} className={styles.advantageIcon} />
                <span className={styles.advantageText}>{advantage.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
