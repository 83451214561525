import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Delivery.module.css'; // Assuming you have a CSS module for styling

const Delivery = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.shippingInfo}>
      <h1>SHIPPING INFORMATION</h1>
      <p>Welcome to {siteName}! We specialize in delivering premium pet bedding quickly and securely. Here’s everything you need to know about our shipping policies:</p>
      
      <h2>1. ORDER PROCESSING TIME</h2>
      <p>At {siteName}, we prioritize swift order processing to ensure your pet bedding reaches you promptly. Processing typically takes 1 to 3 business days, depending on product availability and order volumes. Rest assured, we keep you informed every step of the way.</p>
      
      <h2>2. SHIPPING TIMES AND METHODS</h2>
      <p>Shipping times vary based on your location and the shipping method selected at checkout. Standard shipping generally takes 5-7 business days, while expedited options are available for faster delivery. Estimated delivery times are clearly provided during checkout.</p>
      
      <h2>3. SHIPPING FEES</h2>
      <p>Shipping fees are calculated based on the weight of your order and the destination. We offer free shipping for orders over a specified amount, detailed on our website for your convenience.</p>
      
      <h2>4. TRACKING YOUR ORDER</h2>
      <p>Once your order is shipped, you’ll receive a tracking number via email to monitor its journey. Additionally, you can easily track your order status through your account on {siteName}.</p>
      
      <h2>5. WARRANTY POLICY</h2>
      <p>We stand behind the quality of our pet bedding. If you encounter any defects covered by our warranty, simply contact our dedicated support team within the warranty period along with your receipt. We will assess the issue and determine the appropriate course of action.</p>
      
      <h2>6. HANDLING WARRANTY CLAIMS</h2>
      <p>For valid warranty claims, we promptly address the situation by either repairing or replacing the product at our discretion. If the original item is unavailable, we ensure a substitution of equal or greater value. The warranty period from your original purchase applies to all repairs or replacements.</p>
      
      <h2>7. WARRANTY EXCLUSIONS</h2>
      <p>Our warranty does not cover:</p>
      <ul>
        <li>Normal wear and tear</li>
        <li>Damage due to misuse, accidental or intentional</li>
        <li>Unauthorized modifications or repairs</li>
        <li>Failure to follow maintenance instructions</li>
        <li>External events like accidents or natural disasters</li>
      </ul>
      
      <h2>8. ORDER CONFIRMATION</h2>
      <p>Ensure accurate shipping details during checkout to avoid any delays in receiving your pet bedding.</p>
      
      <h2>9. RETURN PROCESS</h2>
      <p>If a shipment is returned due to incorrect contact information or inability to receive it, we will promptly contact you to arrange another delivery. Additional fees may apply depending on the circumstances.</p>
      
      <h2>10. SHIPPING RESTRICTIONS</h2>
      <p>Some products may have shipping restrictions based on their nature or destination. We will notify you promptly of any such restrictions and propose suitable alternatives when necessary.</p>
      
      <p>For further inquiries or assistance, please reach out to our dedicated customer support team at <a href={`mailto:info@${siteName.toLowerCase()}`}>info@{siteName}</a>. At {siteName}, we are committed to ensuring your pet bedding arrives swiftly and meets your expectations.</p>
    </div>
  );
};

export default Delivery;
